.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--electricLight);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

nav {
  border-bottom: solid rgba(26, 22, 25, .25) 3px;
  margin: auto;
  width: 95%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-bottom: 2.5%;
}

@media (max-width: 500px) {
  nav {
    display: flex;
    justify-content: space-around;
  }
}

form {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
}

label {
  color: var(--dark);
  margin: 15px 0px 5px 0px;
}

input,
textarea {
  border-radius: 5px;
  background-color: var(--white);
  padding: 5px;
  border: none;
  outline: none;
}

textarea:focus {
  outline: none;
}

li {
  position: relative;
}

button {
  color: var(--white);
  width: 70px;
  height: 30px;
  border: none;
  border-radius: 5px;
  background: var(--mid);
}

table {
  margin-top: 25px;
  font-size: 1em;
  width: 90%;
}

@media (max-width: 500px) {
  table {
    font-size: 0.75em;
    margin-top: 5px;
  }
}

td {
  text-align: center;
  color: var(--white);
  padding-top: 15px;
  border-bottom: solid #fff3 3px;
}

tbody {
  border-bottom: solid white 5px;
}

tr {
}

.tableData__button {
  border: none;
}

th {
  color: var(--white);
}

.wallet__button--long {
  width: 100%;
  grid-column: span 3;
  margin: 10px 0px;
}

.short {
  margin-top: 0px;
}

.logo__container {
  padding: 10px;
  display: flex;
  height: 100%;
  width: 15%;
}

@media screen and (max-width: 500px) {
  .logo__container {
    width: 50%;
    display: flex;
    justify-content: flex-start;
  }
  .navbar__logo {
    width: 80%;
  }
}

.navList {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 320px;
  background-color: var(--blue-green);
  border-radius: 15px 0px 0px 15px;
  box-shadow: -2px 0px 10px 1px rgba(25, 25, 25, 0.3);

  /* box-shadow: inset 0px 0px 0px 5px var(--electricLight); */
}

.navList__items {
  display: flex;
}

.navButton__icon {
  position: relative;
  font-size: 1.75em;
  margin: 0px 20px;
  background-color: var(--electric);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  .navList {
    justify-content: center;
    background-color: none;
    box-shadow: none;
    width: 50%;
    margin: 0px;
  }
  .navButton__icon {
    margin: 5px;
    font-size: 1.5em;
  }

  .navList__items {
    display: flex;
  }
}

/* .navButton__icon:hover {
  background-color: var(--electricOP);
  box-shadow: 0px 0px 5px var(--electricOP);
} */

.navPrices {
  font-size: 1.5em;
  color: var(--white);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 85%;
}

.coin__container {
}

.navPrices__slider {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  backdrop-filter: blur(20px);
  border-radius: 15px 25px 25px 15px;
  overflow: hidden;
}


@media (max-width: 500px) {
  .navPrices__slider {
    display: none;
  }
  .navPrices {
    width: 0%;
  }
}

.navPrices__button {
  width: 50px;
  height: 50px;
  margin: 5px 0px;
  border-radius: 10px;
  color: var(--white);
  margin: 5px;
}

.SideCarat {
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.navPrices__input {
  margin: 5px 5px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.navPrices__form {
  flex-direction: row;
  margin: 0px;
}

.navPrices__button--bigger {
  width: 100px;
}

.coin__container {
  display: flex;
}

.coin {
  position: relative;
  display: flex;
  margin: 0px 15px;
  /* border-radius: 15px; */
  border-right: solid rgba(26, 22, 25, .25) 3px;
}

.coin__info {
  font-size: 0.75em;
  text-align: right;
}

.coin__removeButton {
  width: 25px;
  height: 25px;
  background-color: #0002;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px 10px 0px 10px;
}

.x__button {
  width: 50%;
}

@media screen and (max-width: 500px) {
  .coin__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.25em;
    justify-content: flex-end;
  }

  .coin__info {
    font-size: 0.5em;
  }
  .coin {
    padding: 10px 5px;

    margin: 0px;
  }
  .coin__removeButton {
    width: 10px;
  }
}

.dropdown {
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--electric);
  position: absolute;
  top: 60px;
  right: 20px;
  width: 225px;
  border-radius: 15px 15px 15px 15px;
  color: var(--white);
}

.feedback__form {
  display: flex;
}

.feedback__input {
  margin: 5px;
}

.feedback__message {
  padding-bottom: 100px;
  word-wrap: break-word;
}

.feedback__button {
  background-color: var(--light);
  color: var(--dark);
  width: 95%;
  margin: 5px 5px 15px 5px;
}

.dropdown__title {
  padding: 5px;
  margin: 5px auto;
  color: var(--white);
  font-size: 1.5em;
}

.fillerContent {
  margin: 0px 25px 25px 25px;
}

.profileButton__container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.forgotPassword {
  color: var(--dark);
  font-size: 0.75em;
}

.donateButton {
  margin: 5px 5px;
}

.donateButton__container {
  display: flex;
  margin: 10px 5px;
}

.profileButton:hover,
.donateButton:hover {
  box-shadow: 0px 0px 5px 0px var(--electric);
}

.body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80%;
}

@media screen and (max-width: 500px) {
  .body {
    flex-direction: column;
  }
}

.wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  border-right: solid rgba(26, 22, 25, .25) 3px;
  width: 35%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .wallet {
    width: 100%;
    height: 25%;
  }
}

.wallet__slidedown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.wallet__slidedown:hover {
  transform: translateY(0px);
}

.DownCarat {
  margin: 10px auto 25px auto;
  width: 25px;
}

.wallet__form {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  justify-content: space-around;
  align-items: center;
}

.deleteAssets_button {
  width: 100%;
}

.removeAsset__button {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: none;
  width: 25px;
  height: 25px;
  background-color: var(--orange);
}

.removeAsset__button:hover {
  box-shadow: 0px 0px 10px 2px var(--electric);
}

.wallet__input {
  margin: 25px 0px 0px 0px;
}

.wallet__title {
  text-align: center;
  margin-top: 25px;
  color: var(--white);
}

@media (max-width: 500px) {
  .wallet__title {
  }
  .removeX {
    width: 100%;
  }
}

.wallet__list {
  color: var(--white);
  position: relative;
  z-index: -1;
}

.asset {
  display: flex;
}

.content {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .content {
    width: 100%;
    height: 65%;
  }
}

.PieChart__container {
  width: 60%;
  text-align: center;
  margin-top: 25px;
}

.PieChartHeader {
  font-size: 1.5em;
  color: var(--white);
}
